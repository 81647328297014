@import "theme";
$linkColor: #999;
$mobileW: 768px;
$smallMobileW: 480px;
$bigScreenW: 1600px;
$leftWidth: 220px;

$gray: #999;
$lightGray: #eee;

.red {
  background-color: orangered;
}

.blue {
  background-color: lightblue;
}

.black {
  background-color: #111;
}

.purple {
  background-color: rebeccapurple;
}

.green {
  background-color: mediumseagreen;
}



html {
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
}

@media (min-width: 48em) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 58em) {
  html {
    font-size: 20px;
  }
}


/*
 * Sidebar
 *
 * Flexible banner for housing site name, intro, and "footer" content. Starts
 * out above content in mobile and later moves to the side with wider viewports.
 */

.sidebar {
  text-align: center;
  padding: 2rem 1rem;
  color: rgba(255, 255, 255, .5);
  background-color: #202020;
}

@media (min-width: 48em) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 18rem;
    text-align: left;
  }
}

/* Sidebar links */
.sidebar a {
  color: #fff;
}

/* About section */
.sidebar-about h1 {
  color: #fff;
  margin-top: 0;
  font-family: "Abril Fatface", serif;
  font-size: 3.25rem;
}

/* Sidebar nav */
.sidebar-nav {
  margin-bottom: 1rem;
}

.sidebar-nav-item {
  display: block;
  line-height: 1.75;
}

a.sidebar-nav-item:hover,
a.sidebar-nav-item:focus {
  text-decoration: underline;
}

.sidebar-nav-item.active {
  font-weight: bold;
}

/* Sticky sidebar
 *
 * Add the `sidebar-sticky` class to the sidebar's container to affix it the
 * contents to the bottom of the sidebar in tablets and up.
 */

@media (min-width: 48em) {
  .sidebar-sticky {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
}


/* Container
 *
 * Align the contents of the site above the proper threshold with some margin-fu
 * with a 25%-wide `.sidebar`.
 */

.content {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 48em) {
  .content {
    max-width: 38rem;
    margin-left: 20rem;
    margin-right: 2rem;
  }
}

@media (min-width: 64em) {
  .content {
    margin-left: 22rem;
    margin-right: 4rem;
  }
}


/*
 * Reverse layout
 *
 * Flip the orientation of the page by placing the `.sidebar` on the right.
 */

@media (min-width: 48em) {
  .layout-reverse .sidebar {
    left: auto;
    right: 0;
  }
  .layout-reverse .content {
    margin-left: 2rem;
    margin-right: 20rem;
  }
}

@media (min-width: 64em) {
  .layout-reverse .content {
    margin-left: 4rem;
    margin-right: 22rem;
  }
}


/*
 * Themes
 *
 * As of v1.1, Hyde includes optional themes to color the sidebar and links
 * within blog posts. To use, add the class of your choosing to the `body`.
 */

/* Base16 (http://chriskempson.github.io/base16/#default) */

/* Red */
.theme-base-08 .sidebar {
  background-color: #ac4142;
}

.theme-base-08 .content a,
.theme-base-08 .related-posts li a:hover {
  color: #ac4142;
}

/* Orange */
.theme-base-09 .sidebar {
  background-color: #d28445;
}

.theme-base-09 .content a,
.theme-base-09 .related-posts li a:hover {
  color: #d28445;
}

/* Yellow */
.theme-base-0a .sidebar {
  background-color: #f4bf75;
}

.theme-base-0a .content a,
.theme-base-0a .related-posts li a:hover {
  color: #f4bf75;
}

/* Green */
.theme-base-0b .sidebar {
  background-color: #90a959;
}

.theme-base-0b .content a,
.theme-base-0b .related-posts li a:hover {
  color: #90a959;
}

/* Cyan */
.theme-base-0c .sidebar {
  background-color: #75b5aa;
}

.theme-base-0c .content a,
.theme-base-0c .related-posts li a:hover {
  color: #75b5aa;
}

/* Blue */
.theme-base-0d .sidebar {
  background-color: #6a9fb5;
}

.theme-base-0d .content a,
.theme-base-0d .related-posts li a:hover {
  color: #6a9fb5;
}

/* Magenta */
.theme-base-0e .sidebar {
  background-color: #aa759f;
}

.theme-base-0e .content a,
.theme-base-0e .related-posts li a:hover {
  color: #aa759f;
}

/* Brown */
.theme-base-0f .sidebar {
  background-color: #8f5536;
}

.theme-base-0f .content a,
.theme-base-0f .related-posts li a:hover {
  color: #8f5536;
}


// Textures
.texture-black {
  background-image: url('/assets/textures/black.jpg');

  h2 {
    color: #999;
  }
}

.texture-blue {
  background-image: url("/assets/textures/blue.jpg");

  h2 {
    color: #ddd;
  }

  .social i:hover {
    color: #eee;
  }

  //.post-date {
  //  color: #ccc;
  //}
}

.texture-red {
  background-image: url("/assets/textures/red.jpg");
}

.texture-purple {
  background-image: url("/assets/textures/purple.jpg");
}

.texture-green {
  background-image: url("/assets/textures/green.jpg");

  .post-date {
    color: #ccc;
  }
}

.texture-red,
.texture-purple {
  h2 {
    color: #ccc;
  }

  .social i {
    color: #ddd;

    &:hover {
      color: lighten(#ddd, 7%);
    }
  }

  .post-date {
    color: #ddd;
  }
}


.sidebar-about {
  .social {
    overflow: hidden;
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      color: #fff;
      float: left;

      svg {
        margin-right: 15px;
        @include fluidWidth(26px, 34px);
        transition: color .3s;

        &:hover {
          color: var(--gray-900);
        }
      }
    }

    @media (min-width: 48em) {
      justify-content: left;
    }
  }
}

#show-comments-button {
  background-color: #2353A7;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 20px;
  margin: 0px 0px;
  width: 100%;
}

.share-page {
  padding: var(--spacer-2) 0;
}

.share-links a {
  margin: 0 0.2em;
}

.social-icons a,
.share-links a {
  color: var(--heading-color);
  font-size: var(--body-font-size);
  font-weight: 400;
  background-image: none;
  text-decoration: none;
}

.share-links a:hover {
  opacity: 0.88;
}
