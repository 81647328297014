// Messages
//
// Show alert messages to users. You may add it to single elements like a `<p>`,
// or to a parent if there are multiple elements to show.

.message {
  padding: var(--spacer);
  margin-bottom: var(--spacer);
  color: var(--gray-900);
  background-color: var(--yellow-100);
  border-radius: var(--border-radius);
}
